@import url("--vars.css");

#form-section {
  width: 100vw;
  padding: 110px 20px 80px 20px;
  background-color: var(--1b);
  position: relative;
}

#form-circle-abs0 {
  display: none;
  position: absolute;
  top: 140px;
  left: 50%;
  color: var(--1bw);
  box-shadow: 0 0 150px rgba(255, 192, 203, 0.27);
  border-radius: 500px;
  width: 100%;
  min-width: 300px;
  max-width: 700px;
  aspect-ratio: 1;
  background-image: radial-gradient(transparent, rgba(255, 192, 203, 0.15));
  transform: translateX(-50%);
}

#form-circle-abs1 {
  position: absolute;
  top: 280px;
  left: 50%;
  color: var(--1bw);
  box-shadow: 0 0 150px rgba(255, 192, 203, 0.27);
  border-radius: 500px;
  width: 100%;
  min-width: 300px;
  max-width: 700px;
  aspect-ratio: 1;
  background-image: radial-gradient(transparent, rgba(255, 192, 203, 0.15));
  transform: translateX(-50%);
}

/* #form-circle-abs2 {
  position: absolute;
  top: 400px;
  left: 50%;
  color: var(--1bw);
  box-shadow: 0 0 150px rgba(255, 192, 203, 0.27);
  border-radius: 500px;
  width: 100%;
  min-width: 300px;
  max-width: 700px;
  aspect-ratio: 1;
  background-image: radial-gradient(transparent, rgba(255, 192, 203, 0.15));
  transform: translateX(-50%);
} */

#form-circle-abs3 {
  display: block;

  position: absolute;
  top: 100%;
  left: 50%;
  color: var(--1bw);
  box-shadow: 0 0 150px rgba(255, 192, 203, 0.27);
  border-radius: 500px;
  width: 100%;
  min-width: 300px;
  max-width: 700px;
  aspect-ratio: 1;
  background-image: radial-gradient(transparent, rgba(255, 192, 203, 0.15));
  transform: translate(-50%, -200px);
}

#form-circle-abs4 {
  position: absolute;
  bottom: 200px;
  left: 50%;
  color: var(--1bw);
  box-shadow: 0 0 150px rgba(255, 192, 203, 0.3);
  border-radius: 500px;
  width: 100%;
  min-width: 300px;
  max-width: 700px;
  aspect-ratio: 1;
  background-image: radial-gradient(transparent, rgba(255, 192, 203, 0.17));
  transform: translateX(-50%);
}

@media (max-width: 800px) {
  #form-circle-abs0 {
    display: block;
    position: absolute;
    top: 140px;
    left: 50%;
    color: var(--1bw);
    box-shadow: 0 0 150px rgba(255, 192, 203, 0.27);
    border-radius: 500px;
    width: 100%;
    min-width: 300px;
    max-width: 700px;
    aspect-ratio: 1;
    background-image: radial-gradient(transparent, rgba(255, 192, 203, 0.15));
    transform: translateX(-50%);
  }

  #form-circle-abs1 {
    display: block;
    position: absolute;
    top: 280px;
    left: 50%;
    color: var(--1bw);
    box-shadow: 0 0 150px rgba(255, 192, 203, 0.27);
    border-radius: 500px;
    width: 100%;
    min-width: 300px;
    max-width: 700px;
    aspect-ratio: 1;
    background-image: radial-gradient(transparent, rgba(255, 192, 203, 0.15));
    transform: translateX(-50%);
  }

  #form-circle-abs2 {
    display: none;
    position: absolute;
    top: 400px;
    left: 50%;
    color: var(--1bw);
    box-shadow: 0 0 150px rgba(255, 192, 203, 0.27);
    border-radius: 500px;
    width: 100%;
    min-width: 300px;
    max-width: 700px;
    aspect-ratio: 1;
    background-image: radial-gradient(transparent, rgba(255, 192, 203, 0.15));
    transform: translateX(-50%);
  }

  #form-circle-abs3 {
    display: block;

    position: absolute;
    top: 100%;
    left: 50%;
    color: var(--1bw);
    box-shadow: 0 0 150px rgba(255, 192, 203, 0.27);
    border-radius: 500px;
    width: 100%;
    min-width: 300px;
    max-width: 700px;
    aspect-ratio: 1;
    background-image: radial-gradient(transparent, rgba(255, 192, 203, 0.15));
    transform: translate(-50%, -200px);
  }

  #form-circle-abs4 {
    display: none;
    position: absolute;
    bottom: 200px;
    left: 50%;
    color: var(--1bw);
    box-shadow: 0 0 150px rgba(251, 180, 192, 0.3);
    border-radius: 500px;
    width: 100%;
    min-width: 300px;
    max-width: 700px;
    aspect-ratio: 1;
    background-image: radial-gradient(transparent, rgba(251, 180, 192, 0.2));
    transform: translateX(-50%);
  }
}

#form-section-title {
  z-index: 5;
  font-size: 55px;
  color: var(--g);
  margin-bottom: 25px;
  padding: 15px 20px 10px 10px;
  text-align: center;
}

/* #form-section-title,
* {
  padding-left: 5;
} */

#form-section-title0 {
}

#form-section-title1 {
}

#form-section-title2 {
}

#form {
  justify-content: center;
  width: 100%;
  max-width: 400px;
  min-height: 650px;
  box-shadow: 0 0 5px var(--main);
  border: 1px solid var(--main);
  background-color: var(--wt);
  padding: 40px 10px 50px 10px;
  z-index: 5;
}

#form-title {
  margin-bottom: 20px;
  font-size: 32px;
}

.form-span {
  margin: 10px 0;
}

#form-submit-button {
  margin-top: 40px;
  padding: 10px;
  /* background-color: transparent; */
  background-color: var(--1b);
  color: pink;
  font-size: 30px;
  box-shadow: 10px 10px 0 var(--g);
  cursor: pointer;
}

.form-input {
  min-width: 300px;
  background-color: transparent;
  padding: 10px;
  border: 1px solid rgb(112, 112, 112);
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}

input[type="radio"]:checked {
  /* color: red; */
  background-color: var(--g);
  border: 1px solid rgb(104, 104, 104); /* Цвет выбранной радиокнопки */
}

input[type="radio"] {
  margin-right: 7px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid rgb(214, 214, 214);
  outline: none;
  position: relative;
  /* transition: border-color 0.3s ease; */
}

input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(
    255,
    175,
    188
  ); /* Цвет точки внутри выбранной радиокнопки */
}
input[type="checkbox"]:checked::before {
  content: "✓";
  transform: translate(-50%, -50%) scale(1.4);
  position: absolute;
  color: var(--1b);
  display: block;
  width: 10px; /* Размер галочки */
  height: 10px;
  border-width: 0 2px 2px 0;
  /* transform: rotate(45deg); */
  margin: 2px 5px;
}

/* Изменение цвета бокса */
input[type="checkbox"] {
  margin-right: 7px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  content: "✓";
  color: black;
  width: 16px; /* Размер бокса */
  height: 16px;
  border: 2px solid #ccc; /* Цвет бокса */
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

input[type="checkbox"]:checked {
  content: "Y";
  color: black;
  border: 3px solid var(--g);
  border-color: var(--g); /* Цвет бокса при отметке */
  background-color: pink; /* Цвет заливки бокса при отметке */
}

.form-input:focus {
  border-color: pink !important; /* Изменение цвета рамки */
  box-shadow: 0 0 5px pink !important; /* Добавление тени */
}

.form-label {
  margin-top: 5px;
  margin-bottom: 20px;
}
