@import url("../../css/--fonts-imports.css");

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse-color {
  0% {
    opacity: 0.9; /* text-gray-800 */
  }
  50% {
    opacity: 0.33;
  }
  100% {
    opacity: 0.9; /* text-gray-800 */
  }
}

.fade-in {
  animation: fadeIn 1.4s ease forwards;
}

.fade-out {
  animation: fadeOut 2.4s ease forwards;
}

#white-screen {
  position: fixed;
  z-index: 101;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

#gallery-page {
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: rgb(28, 28, 28);
  display: block;
  opacity: 1;
  flex-grow: 1;
  position: relative; /* Add this */
  overflow: hidden;
}

#gallery-main-photo-wrapper-supreme {
  background-color: rgb(7, 7, 7);
  overflow: hidden;
}
#gallery-main-photo-wrapper {
  margin: auto;
  background-position: center bottom;
  position: relative;
  max-width: 1200px;
  width: 100%;
  min-height: 645px;
  max-height: 500px;
  background-color: rgb(7, 7, 7);
  background-image: url("./main.webp");
  background-size: 165%;
  background-repeat: no-repeat;
  z-index: 0;
  transform: translateY(30px);
  /* border-bottom-right-radius: 30%;
  border-bottom-left-radius: 30%; */
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.75),
    inset 0 0 35px rgba(0, 0, 0, 0.95);
}

/* @media screen and (min-width: 500px) {
  #gallery-main-photo-wrapper {
    background-size: 150%;
  }
} */

/* 123
@media screen and (min-width: 400px) {
  #gallery-main-photo-wrapper {
    min-height: 645px;
  }
} */

@media screen and (min-width: 563px) {
  #gallery-main-photo-wrapper {
    background-size: 137%;
  }
}

@media screen and (min-width: 700px) {
  #gallery-main-photo-wrapper {
    background-size: 120%;
    transform: translateY(45px);
  }
}

@media screen and (min-width: 779px) {
  #gallery-main-photo-wrapper {
    background-size: 105%;
    transform: translateY(50px);
  }
}

@media screen and (min-width: 886px) {
  #gallery-main-photo-wrapper {
    background-size: 100%;
    min-height: 700px;

    /* transform: translateY(50px); */
  }
}

@media screen and (min-width: 950px) {
  #gallery-main-photo-wrapper {
    min-height: 800px;
  }
}

/* @media screen and (min-width: 100px) {
  #gallery-main-photo-wrapper {
    min-height: 950px;
    transform: translateY(70px);
  }
} */

/* #gallery-main-photo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1280px;
  object-position: center;
  object-fit: cover;
  height: auto;
  z-index: 0;
  min-width: 850px;
  width: auto;
  margin-bottom: -4px;
  border-radius: 20%;
  overflow: hidden;
  display: none;
} */

/* #gallery-main-photo-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: linear-gradient(to center, rgb(170, 255, 0), rgb(170, 255, 0));
  pointer-events: none;
} */

#gallery-top-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  height: 175px;
  background-image: linear-gradient(
    to bottom,
    rgba(7, 7, 7, 1),
    rgba(7, 7, 7, 0.95),
    rgba(7, 7, 7, 0.55),
    /* rgba(7, 7, 7, 0.25), */ transparent
  );
  /* background-image: linear-gradient(
    to bottom,
    rgb(84, 0, 0),
    rgba(84, 0, 0, 0.9),
    rgba(84, 0, 0, 0.4),
    transparent
  ); */
}

#gallery-photo-link {
  color: rgb(51, 51, 51);
  position: absolute;
  z-index: 2;
  top: -20px;
  left: 10px;
  font-style: normal;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 900;
  cursor: pointer;
  font-style: italic;
  transition: all 0.3s ease;
}

@media screen and (min-width: 700px) {
  #gallery-photo-link {
    left: 20px;
  }
}

@media screen and (min-width: 950px) {
  #gallery-photo-link {
    left: 30px;
  }
}

#gallery-photo-link:hover {
  color: rgb(71, 71, 71);
}

#gallery-photo-sign {
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    rgb(203, 180, 169),
    rgb(110, 90, 62)
  );
  line-height: 1.6;
  /* outline: 2px solid slateblue; */
  margin: auto;
  overflow: visible;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-family: "GoodVibesPro";
  font-size: min(14vw, 60px);
  left: 0;
  width: 100%;
  text-align: center;
  top: 7.5%;
  z-index: 2;
  filter: blur(0.425px);
  /* font-size: 50px; */
}

@media screen and (min-width: 1150px) {
  #gallery-photo-sign {
    top: 5%;
  }
}

#gallery-rest {
  margin: 0 auto; /* center the container if desired */
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  max-width: 1200px; /* optional: set a maximum width */
  flex: 1;
  flex-shrink: 0;
  flex-grow: 1;
  min-height: 100vh;
  /* transform: translateY(-170px); */
}

#gallery-rest-folder {
  width: 100%; /* or any specific width */
  margin: 0 auto; /* center the container if desired */
  opacity: 1;
  z-index: 1;
  /* transition: opacity 0.45s ease-in; */
  /* transform: translateY(-170px); */
  background-color: rgb(28, 28, 28);
  padding-bottom: 77px;
  /* overflow-x: hidden !important;
  overflow-y: visible !important; */
}

#gallery-rest-folder.hidden {
  opacity: 0;
}

#gallery-footer {
  /* font-size: 14px; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgb(181, 181, 181);
  font-style: italic;
  margin-top: auto;
  margin-bottom: 0;
  height: 90px;
  width: 100%;
  background-color: rgb(0, 0, 0);
  z-index: 100;
  position: relative;
  border-top: 1px solid rgba(53, 53, 53, 0.7);
  box-shadow: 0 -17.5px 20px rgba(0, 0, 0, 0.5);
}

.gallery-folder-photo {
  display: flex;
  position: relative;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
  margin: 0;
  padding: 0;
}

.gallery-folder-photo-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.gallery-folder-photo-svg {
  fill: rgba(187, 170, 129, 0.5);
  height: auto;
  position: absolute;
  width: 33%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .gallery-folder-photo:hover {
  scale: 1.025;
} */

#gtb {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 10;
  padding: 10px;
}

/* .animate-pulse {
  animation: pulse-color 3s cubic-bezier(0.75, 0.75, 0.75, 0.75) infinite;
} */

.gallery-folder-save-button {
  padding: 7.5px 15px;
  font-style: italic;
  background-image: linear-gradient(
    to bottom,
    rgb(205, 255, 182),
    rgb(138, 244, 63)
  );
  border-radius: 5px;
  white-space: nowrap;
  color: rgb(7, 7, 7);
  border: none;
  cursor: pointer;
}

.gallery-folder-save-button-wr {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 20px; */
  padding-bottom: 5px;
}

.gallery-global-announcement {
  margin: auto;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border: 2px solid rgba(181, 181, 181, 0.4);
  border-radius: 10px;
  padding: 10px;
  /* padding-top: 25px; */
  margin-bottom: 7px;
  /* margin-top: 12.5px; */
  position: relative;
  max-width: 600px;
  transition: all 0.3s ease;
}

.gallery-global-announcement * {
  transition: all 0.3s ease;
}

.gallery-global-announcement-x {
  position: absolute;
  right: 10px;
  bottom: 100%;
  color: rgba(181, 181, 181, 0.8);
  font-style: italic;
}

/* .gallery-global-announcement-last {
  margin-bottom: 35px;
} */

.gallery-global-announcement-p {
  /* border: 1px solid chartreuse; */
  width: 100%;
  color: rgb(210, 210, 210);
  font-size: 16px;
  margin-right: 10px;
}

.gallery-global-announcement-arrow {
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
}

.arrow-svg {
  width: 35px;
  height: 35px;
  fill: rgba(210, 210, 210, 0.9);
  /* transform: rotate(180deg); */
}

.gallery-rest-folder-h1 {
  font-size: 37px;
  color: rgba(210, 210, 210, 0.9);
  font-style: italic;
  /* transform: scaleY(105%); */
}

.gallery-rest-folder-h1-wr {
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -20px 10px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.3s ease; /* overflow-x: hidden !important; */
}

.gallery-global-announcement-wr {
  z-index: 1;
  padding: 27.5px 5px 35px 5px;
  /* border: 2px solid salmon; */
}

.gallery-footer-text {
  position: absolute;
  /* width: 57.5px;
  height: 57.5px;
  background-color: rgb(222, 222, 222);
  border: 2px solid rgb(168, 168, 168); */
  /* box-shadow: 0 0 20px black; */
  /* border-radius: 50%; */
  left: 50%;
  top: -47px;
  font-style: normal;
  transform: translateX(-50%);
  color: rgb(91, 91, 91);
  text-decoration: underline;
  cursor: pointer;
}

.gallery-footer-button-arrow {
  width: 100%;
  height: 100%;
  fill: rgb(52, 52, 52);
  transform: rotate(180deg);
}

.bg-black {
  background-color: black;
}

.lightbox-svg {
  /* position: fixed; */
  background-color: chartreuse;
  /* top: 0;
  left: 0;
  z-index: 999; */
}
