@import url("--vars.css");

#loading-page {
  width: 100vw;
  height: 100vh;
  transition: opacity 2s ease;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(252, 252, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20vh;
  z-index: 199;
  opacity: 1;
}

#loading-page * {
  text-align: center;
  font-family: "Informer", sans-serif;
}

#loading-page.fade-out {
  opacity: 0;
}

#loading-page-margin {
  width: 100%;
  height: 20vh;
}

#loading-page-title {
  z-index: -1;
}

#loading-page-title {
  opacity: 0.9;
  font-size: 26px;
  /* font-style: italic; */
}
