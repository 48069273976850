@font-face {
  font-family: "Informer";
  src: url("./fonts/informer.ttf") format("truetype");
}
/* 
@font-face {
  font-family: "scriptThinPen";
  src: url("./fonts/scriptThinPen.ttf") format("truetype");
}

@font-face {
  font-family: "Classica";
  src: url("./fonts/classica.ttf") format("truetype");
}
@font-face {
  font-family: "ComicHelvetic";
  src: url("./fonts/comicHelvetic/ComicHelvetic_Light.otf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "ComicHelvetic";
  src: url("./fonts/comicHelvetic/ComicHelvetic_Medium.otf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "ComicHelvetic";
  src: url("./fonts/comicHelvetic/ComicHelvetic_Heavy.otf") format("truetype");
  font-weight: 700;
} */

@font-face {
  font-family: "SantaCatarina";
  src: url("./fonts/SantaCatarina.otf") format("truetype");
  font-weight: 400;
}
/* 
@font-face {
  font-family: "MorfinSans";
  src: url("./fonts/MorfinSans.ttf") format("truetype");
  font-weight: 400;
} */

/* @font-face {
  font-family: "MorfinSans";
  src: url("./fonts/MorfinSans.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Caravan";
  src: url("./fonts/Caravan.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "MrLonely";
  src: url("./fonts/MrLonely.otf") format("opentype");
  font-weight: 400;
} */
@font-face {
  font-family: "GoodVibesPro";
  src: url("./fonts/GoodVibesPro.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Oceanic";
  src: url("./fonts/Oceanic/OceanicRegular.otf") format("opentype");
  font-weight: 400;
}
/* 
@font-face {
  font-family: "Naga";
  src: url("./fonts/Naga.ttf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Elowen";
  src: url("./fonts/Elowen.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Pehlevi";
  src: url("./fonts/Pehlevi.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Intro";
  src: url("./fonts/Intro.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Mavka";
  src: url("./fonts/Mavka.ttf") format("truetype");
  font-weight: 400;
} */
