@import url("../../css/--vars.css");

#gallery-header-wr {
  width: 100vw;
  height: 81px;
  position: relative;
}

#gallery-header {
  position: absolute;
  z-index: 10;
  /* margin: auto; */
  background-color: rgb(7, 7, 7);
  color: white;
  width: 100vw;
  overflow: visible;
  padding: 20px 0;
  /* border-top: 1px solid var(--main); */
  border-bottom: 1px solid var(--main);
  box-shadow: 0 0 15px black;
}

#gallery-header * {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#gallery-header *::-webkit-scrollbar {
  display: none;
}

#gallery-header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgb(7, 7, 7);
  padding: 20px 0;
  box-shadow: 0 0 15px black;
  overflow: visible;
  color: white;
  width: 100vw;
  border-bottom: 1px solid var(--main);
}

#gallery-header-fixed * {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#gallery-header-fixed *::-webkit-scrollbar {
  display: none;
}

#gallery-header * {
  z-index: 10;
}

#gallery-header.visible {
  transform: translateY(0);
}

#gallery-header.hidden {
  transform: translateY(-100%);
}

#gallery-header-ul {
  max-width: 1200px;
  margin: auto;
  overflow: scroll;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

@media (max-width: 685px) {
  #gallery-header-ul {
    width: 100vw;
    overflow: scroll;
  }
}

#gallery-header-left-margin {
  display: block;
  background-color: aqua;
  width: 20px;
  height: 100%;
}

.gallery-header-item {
  cursor: pointer;
  margin: 5px 7px 0 7px;
  padding: 7px 17px;
  border: 1px solid rgb(53, 53, 53);
  border-radius: var(--br);
  flex-grow: 0;
  opacity: 0.7;
}

.gallery-header-item:last-of-type {
  margin-right: 0;
}

.gallery-header-item:first-of-type {
  margin-left: 20px;
}

.gallery-header-item-title {
  white-space: nowrap;
  font-style: italic;
  transform: scaleX(1.2);
}

#anketa {
  background-color: var(--bg);
}

.gallery-header-item:hover {
  opacity: 1;
}

.gallery-header-item-title-gallery {
  background-color: rgb(58, 58, 58);
  background-color: rgb(140, 187, 213);
  /* color: aliceblue; */
  /* font-family: "GoodVibesPro";
  font-size: x-large; */
}
