@import url("--vars.css");

#header {
  background-color: var(--bg);
  width: 100vw;
  /* height: 70px; */
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 10;
  transition: transform 0.4s ease;
  padding: 20px 0;
  box-shadow: 0 0 15px rgba(var(--dmain), 0.41);
  /* box-shadow: var(--boxsh); */

  /* background-color: var(--bg); */
}

#header * {
  z-index: 10;
}

#header.visible {
  transform: translateY(0);
}

#header.hidden {
  transform: translateY(-100%);
}

#header-ul {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

@media (max-width: 845px) {
  #header-ul {
    width: 100vw;
    overflow: scroll;
  }
}

#header-left-margin {
  display: block;
  background-color: aqua;
  width: 20px;
  height: 100%;
}

.header-item {
  cursor: pointer;
  margin: 5px 7px 0 7px;
  padding: 7px 17px;
  border: 1px solid var(--main);
  border-radius: var(--br);
  flex-grow: 0;
  opacity: 0.7;
  transition: background-color 0.2s ease, opacity 0.2s ease; /* Added transition for opacity */
}

.header-item:first-of-type {
  background-color: rgba(161, 161, 114, 0.95);
  opacity: 1;
  color: white;
  text-shadow: 1px 1px 1px var(--main);
  transition: background-color 0.3s ease; /* Added transition */
}

.header-item:first-of-type:hover {
  background-color: rgb(147, 147, 104);
}

.header-item:last-of-type {
  margin-right: 0;
}

.header-item:first-of-type {
  margin-left: 20px;
}

.header-item-title {
  white-space: nowrap;
  font-style: italic;
  transform: scaleX(1.2);
}

#anketa {
  background-color: var(--bg);
}

.header-item:hover {
  opacity: 1;
}

.header-item-title-gallery {
  background-color: rgb(58, 58, 58);
  background-color: rgb(140, 187, 213);
  /* color: aliceblue; */
  /* font-family: "GoodVibesPro";
  font-size: x-large; */
}
