@import url("--vars.css");

.anketa {
  position: relative;
  background-color: aliceblue;
}

.anketa * {
  z-index: 1;
}

#anketa-back-abs {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../images/sky.png");
  background-size: cover;
  z-index: 0;
  opacity: 0.5;
}
