@import url("--vars.css");

#countdown-section {
  position: relative;
  width: 100%;
  height: 100px; /* DEP 111 */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 -40px 40px var(--bg), 0 -20px 20px var(--bg);
  /* z-index: 2; */
  /* outline: 3px solid slateblue; */
}

#edge-abs {
  top: 0;
  width: 100%;
  height: 20px;
  position: absolute;
  background-color: var(--bg);
  transform: translateY(-20px);
  border-top-left-radius: var(--br);
  border-top-right-radius: var(--br);
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); */
  /* z-index: -2; */
  /* outline: 3px solid rgb(0, 255, 30); */
}

#countdown-div {
  z-index: 2;
  /* outline: 3px solid rgb(255, 251, 0); */
  width: 100%;
  max-width: min(600px, 88vw);
  position: absolute;
  transform: translateY(-35%);
  border-radius: var(--br);
  background-color: var(--bg);
  box-shadow: 0 -20px 20px var(--bg), 20px -20px 20px var(--bg),
    -20px -20px 20px var(--bg);
}

#countdown-div-rel {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
  padding: 20px;
  position: relative;
}

@media (max-width: 800px) {
  #countdown-div-rel {
    padding: 15px;
    gap: 15px;
  }
}

#countdown-div-branch-left-abs {
  position: absolute;
  width: 25vw;
  height: 10vw;
  overflow: visible;
  /* outline: 3px solid salmon; */
  bottom: 0;
  left: 0;
  background-image: url("../images/branch.svg");
  background-size: cover;
  /* background-position: center center; */
  transform: translate(0%, 0%) rotate(0deg) scaleX(-1);
  z-index: -1;
}

#countdown-div-branch-right-abs {
  position: absolute;
}

/* #countdown-div > #countdown-div-item:first-child {
  margin-left: 20px;
  outline: 3px solid slateblue;
}

#countdown-div > #countdown-div-item:last-child {
  margin-right: 20px;
  outline: 3px solid slateblue;
} */

.countdown-div-item {
  /* width: 140px;
  height: 140px; */
  aspect-ratio: 1;
  /* background-color: rgba(245, 252, 255, 0.535); */
  background-image: var(--cdg);
  border-radius: var(--br);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: min(46px, 7vw);
  color: var(--main);
  border: 2px solid rgba(67, 67, 67, 0.15);
  box-shadow: var(--boxsh);
}

#countdown-sign-abs {
  height: min-content;
  position: absolute;
  word-break: keep-all;
  width: max-content;
  top: -25px;
  left: 2.5%;
  z-index: 2;
  font-size: min(5.7vw, 30px);
  box-shadow: 10px -10px 30px var(--wc), 0 -10px 20px var(--wc),
    0 -10px 20px var(--wc);
  text-shadow: 1px 1px 1px rgb(255, 255, 255), 3px 3px 3px rgb(187, 187, 187);
  color: rgba(var(--dmain), 0.7);
  z-index: 5;
  background-color: var(--wc);
  transform: scaleY(1.1);
}

.countdown-div-item-span {
  font-size: min(22px, 3.2vw);
}

.countdown-div-item-digits {
}
