@import url("./css/--fonts-imports.css");
@import url("./css/--font-appetite-pro");

@import url("./css/--shared.css");
@import url("./css/--vars.css");

@import url("./css/loading-page.css");
@import url("./css/lock-page.css");
@import url("./css/main-page.css");
@import url("./css/anketa.css");

/* @import url("./components/paceholder/plh.css"); */

@import url("./css/header.css");
@import url("./css/main-picture.css");
@import url("./css/photo-viewer.css");
@import url("./css/countdown-section.css");
@import url("./css/main-section.css");
@import url("./css/faq-section.css");
@import url("./css/love-story.css");
@import url("./css/program-section.css");
@import url("./css/address-section.css");
@import url("./css/form-section.css");
@import url("./css/to-top-button.css");
@import url("./css/footer.css");

@import url("./pages/GalleryPage/gallery-page.css");
@import url("./pages/GalleryPage/gallery-header.css");
@import url("./pages/GalleryPage/captcha.css");

/* Для WebKit-браузеров (Chrome, Safari, Opera) */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* Для Internet Explorer и Edge */
* {
}

/* Для Firefox */

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  background-color: rgb(255, 255, 255);
}

body {
  width: 100%;
  font-family: "Oceanic", sans-serif;
}

#root {
  width: 100%;
}

p {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  font-style: italic;
  color: var(--main);
}

h2 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 30px;
  color: var(--main);
  font-weight: 400;
}

h3 {
  text-align: center;
  font-size: 20px;
  color: var(--main);
  margin: 0 0 20px 30px;
  font-weight: 400;
}

h4 {
  text-align: center;
  font-size: 20px;
  color: var(--main);
  font-weight: 400;
}

a {
  text-align: center;
  color: var(--main);
  text-decoration: underline;
  cursor: pointer;
}
