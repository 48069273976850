@import url("--vars.css");

#lock-screen {
  height: 100vh;
  position: fixed;
}

#lock-screen-rel {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
}

#lock-screen-video {
  /* position: absolute; */
  width: 100vw;
  height: calc(100vh + 30px);
  object-fit: cover;
  filter: brightness(88%);
  z-index: 1;
  clip-path: inset(30px 0 0 0);
  top: -30px;
  transform: translateY(-30px);
}

#lock-screen-content-div-abs {
  position: absolute;
  bottom: 35px;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#lock-screen-controller {
  /* right: 50%; */
  height: 75px;
  width: 310px;
  max-width: 90%;
  outline: 2px solid rgb(144, 128, 98);
  border-radius: 50px;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 5px; */
  position: relative;
  box-shadow: 0 0 7px rgba(255, 255, 255, 1);
}

#lock-screen-controller-arrow-back {
  height: 75px;
  width: 75px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.8);
}

.draggable-element {
  position: absolute;
  cursor: grab;
}

.draggable-element:active {
  cursor: grabbing;
}

#lock-screen-controller-arrow {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background-image: url("../images/arrow1.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
}

#lock-screen-controller-circle {
}

#lock-screen-title {
  font-size: min(45px);
  color: rgb(155, 138, 107);
  font-family: "Classica";
  line-height: 1.2;
  margin-bottom: 8px;
  z-index: 3;
  text-shadow: 0 0 2px rgba(255, 255, 255, 1);
}

#lock-screen-controller-note-abs {
  font-size: 24px;
  letter-spacing: 3px;
  z-index: -1;
  transform: translateY(-4px);
  padding-left: 55px;
  color: rgba(128, 128, 128, 0.3);
  margin: 0 auto;
}
