@import url("--vars.css");

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#photo-viewer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

#photo-viewer * {
  z-index: 999;
}

#photo-viewer-top {
  top: 0;
  left: 0;
  position: fixed;
  height: 50px;
  width: 100%;
  /* background-image: linear-gradient(
    to bottom,
    rgba(255, 245, 234, 0.75) 70%,
    transparent
  ); */
  background-color: var(--bg);
  border-top-left-radius: var(--br);
  border-top-right-radius: var(--br);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 20px;
  box-shadow: 0 0 15px rgba(var(--dmain), 0.41);
}

#photo-viewer-top-title-wr {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center; /* This centers the text vertically within the div */
  transform: translateY(1px);
}

#photo-viewer-top-title {
  font-size: 32px;
  flex: 0 0 auto; /* This prevents the title from growing or shrinking */
  /* display: flex; */
  /* align-items: center; This centers the text vertically within the div */
  height: min-content;
}

#photo-viewer-top-close-div {
  position: relative;
  /* top: 10px;
  right: 5px; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: var(--br);
  outline: 2px solid var(--main);
  z-index: 991;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--bg);
  cursor: pointer;
}

#photo-viewer-top-close-div-close {
  margin: 0;
  font-size: 18px;
}
#photo-viewer-rel {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#photo-viewer-arrow-wrapper-left {
  height: 100vh;
  width: 20vw;
  /* background-color: rgba(141, 129, 83, 0.387); */
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

#photo-viewer-arrow-left {
  width: 50px;
  height: 50px;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(180deg);
  margin-left: 10px;
  background-image: url("../images/arrow-angled-pink.svg");
  background-size: cover;
  cursor: pointer;
}

#photo-viewer-arrow-wrapper-right {
  height: 100vh;
  width: 20vw;
  /* background-color: rgba(141, 129, 83, 0.387); */
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

#photo-viewer-arrow-right {
  width: 50px;
  height: 50px;
  transform: translateY(-50%);
  margin-right: 10px;
  background-image: url("../images/arrow-angled-pink.svg");
  background-size: cover;
  cursor: pointer;
}

#photo-viewer-loading-img {
  width: 55px;
  height: 55px;
  margin-bottom: 10vh;
  animation: rotate 3s linear infinite;
}

#photo-viewer-main-img {
  margin-top: 50px;
  margin-bottom: 25px;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
}
