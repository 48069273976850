@import url("--vars.css");

#main-page {
  width: 100%;
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
