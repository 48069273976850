.captcha {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(85, 85, 85, 0.5);
  backdrop-filter: blur(4px);
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
}

.captcha-inner {
  width: 100%;
  max-width: 600px;
  background-color: rgb(40, 40, 40);
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid rgba(189, 189, 189, 0.175);
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .captcha-inner {
    transform: translateY(-10%);
  }
}

.captcha-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: max(28px, min(3.5vw, 33px));
  color: rgb(189, 189, 189);
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .captcha-btn {
    font-size: 27px;
  }
}

.captcha-input-wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px; /* Added a small gap for visual separation */
  max-width: 450px;
  margin: 0 auto;
  box-sizing: border-box;
}

.captcha-input-div {
  display: flex;
  justify-content: center;
  width: 100%;
  /* height: 100%; */
  aspect-ratio: 1;
  text-align: center;
  font-size: 30px;
  border: 2px solid rgb(189, 189, 189);
  background-color: transparent;
  border-radius: 8px;
  transition: border-color 0.3s;
  padding: 0; /* Remove any default padding */
  margin: 0; /* Remove any default margin */
  box-sizing: border-box; /* Ensure padding doesn't affect overall size */
}
.captcha-input-div:focus {
  border: 2px solid rgb(182, 69, 69);
}

.captcha-input {
  margin: 0 !important;
  display: flex;
  align-items: center;
  color: rgb(233, 233, 233);
  text-align: center;
  font-size: max(28px, min(3.5vw, 33px));
  background-color: transparent;
  height: 100%;
  border: none;
}

.captcha-input:focus {
  border: none;
  outline: none;
}

.captcha-btn {
  margin-top: 40px;
  margin-bottom: 40px;
  border: 2px solid rgb(189, 189, 189);
  background-color: transparent;
  color: rgb(189, 189, 189);
  font-size: max(17px, min(2.9vw, 24px));
  padding: min(3.7vw, 18px);
  border-radius: 8px;
}

/* @media screen and (max-width: 600px) {
  .captcha-btn {
    font-size: 22px;
    padding: 15px 25px;
  }
} */
