:root {
  /* --bg: rgb(249, 252, 255); */
  --bg: rgb(243, 243, 243);
  --1bw: rgb(250, 246, 242);
  --wt: rgb(250, 246, 242);
  --g: rgb(184, 161, 107);
  --gd: rgb(113, 101, 74);
  -dwt: 250, 246, 242;
  --wc: rgb(243, 243, 243);
  --1b: #1b1b1b;
  --d1b: 2, 27, 27;
  --dbg: 249, 252, 255;
  --main: rgb(33, 33, 33);
  --ts: rgb(0, 5, 59);
  --tr: transparent;
  --dmain: 33, 33, 33;
  --dmaina: 33, 33, 33, 0.5;
  --cdg: linear-gradient(
    177deg,
    rgba(255, 255, 255, 0.6) 62%,
    rgba(208, 231, 255, 0.7)
  );
  --cdg1: linear-gradient(
    177deg,
    rgba(208, 231, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.6) 20%,
    rgba(255, 255, 255, 0.6) 62%,
    rgba(208, 231, 255, 0.45) 100%
  );
  --boxsh: 0 0 17px rgba(var(--dmain), 0.3);
  --br: none;
}
