@import url("--vars.css");
/* 
@keyframes fadeIn {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: auto;
  }
} */

#faq-section {
  margin-top: 90px;
  width: 100%;
  max-width: 850px;
  padding: 0 15px;
  /* background-color: var(--1b); */
}

#faq-title {
  text-align: start;
  font-size: 30px;
  line-height: 0.9;
  font-style: italic;
  align-self: flex-start;
  margin-left: 20px;
  margin-bottom: 45px;
}

.faq-item {
  width: 100%;
  outline: 3px solid rgb(219, 219, 219);
  border-radius: var(--br);
  padding: 20px;
  margin-bottom: 20px;
  transition: height 0.4s ease; /* Changed 'smooth' to 'ease' */
  background-image: var(--cdg1);
  box-shadow: var(--boxsh);
  overflow: hidden;
  border-radius: 5px;
  transform: scale(1); /* Add this to set the initial scale */
}

.faq-item.true {
  height: max-content;
}

.faq-item:hover {
  transform: scale(1.025); /* Use transform instead of scale */
  transition: transform 0.3s ease; /* Specify transform in the transition */
}

.faq-item-title-wrapper {
  /* outline: 3px solid salmon; */
  /* padding: 10px 0; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq-item-title {
  margin: 0;
  /* margin-left: 10px; */
  text-align: start;
  font-size: 18px;
  font-weight: 900;
  animation: fadeIn 0.3s ease forwards;
  overflow: hidden;
}

.faq-item-title-img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background-image: url("../images/arrow-angled.svg");
  background-size: cover;
  transform: rotate(90deg);
  opacity: 0.75;
}

.faq-item-title-img.true {
  transform: rotate(-90deg);
}

.faq-item-answer {
  box-sizing: border-box;
  max-height: 0;
  font-style: normal;
  font-size: 18px;
  /* padding: 10px 0 10px 10px; */
  padding: 0;
  transition: opacity 0.4s ease, max-height 0.4s ease, padding 0.4s ease;
  /* display: none; */
  opacity: 0;
}

.faq-item-answer.true {
  /* display: block; */
  max-height: 500px;
  opacity: 1;
  /* padding: 10px 0 10px 10px; */
}

/* .faq-item-answer-span {
  max-height: 0 !important;
}

.faq-item-answer-span.true {
  max-height: 300px;
}

.faq-item-answer.true * {
  transition: all 0.3s ease-in-out;
} */
