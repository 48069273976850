@import url("--vars.css");

#to-top-button-section {
  height: 140px;
  width: 100%;
  justify-content: center;
  color: var(--gd);
  z-index: 2;
  position: relative;
}
/* #grass {
  position: absolute;
  width: 100%;
  height: 40vw;
  max-height: 250px;
  top: 100%;
  transform: translateY(-100%);
  background-image: url("../images/grass.png");
  background-size: contain;
  opacity: 0.8;
  z-index: -1;
} */
