@import url("--vars.css");

#footer {
  /* padding: 2px 0; */
  height: 57px;
  width: 100%;
  background-color: var(--1b);
  justify-content: center;
  color: var(--wc);
  position: relative;
  text-align: center;
  line-height: 1;
}

#footer-c-span {
  font-size: 9;
}

#footer-span {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 2px;
}

/* #footer-back-abs {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../images/ground-pattern.png");
  background-size: contain;
  opacity: 0.4;
} */
