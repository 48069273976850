@import url("--vars.css");

.program-section-program-container-item-abs {
  position: absolute;
  height: 100%;
  width: 30px;
  left: 0;
  top: 0;
  transform: translateX(-30px);
  background-image: linear-gradient(to right, transparent, white);
}

#program-section {
  position: relative;
  padding: 40px 0 40px 0;
}

#program-section-baloons-abs {
  right: -150px;
  top: 250px;
  position: absolute;
  width: 300px;
  height: 300px;
  background-image: url("../images/15.svg");
  background-size: contain;
  background-repeat: no-repeat;
  /* transform: rotate(15deg); */
  opacity: 0.15;
  z-index: 0;
}

#program-section-title {
  font-size: 55px;
  letter-spacing: 4px;
  text-shadow: 4px 0 0 var(--wt), 0 55px 300px rgba(var(--d1b), 0.7);
}

#program-section-title0 {
  position: relative;
}

#program-section-title1 {
  position: relative;
}

#program-section-title2 {
  position: relative;
}

#program-section-subtitle {
  font-size: 24px;
}

#program-section-subtitle-img {
  width: 140px;
  height: 40px;
  transform: translateY(-25%);
  background-image: url("../images/16.svg");
  background-size: cover;
  opacity: 0.65;
}

#program-section-subtitle-wrapper {
  /* align-self: center; */
  width: min-content;
  background-color: var(--wc);
  /* box-shadow: 4px 4px 0 var(--wt); */
  margin-bottom: 30px;
}
#program-section-subtitle {
  padding: 0 2px 2px 0;
  text-shadow: -2px 2px 0 var(--wt);
}

.program-section-program-container-item-wrapper {
  display: flex;
  width: 100%;
  outline: 3px solid slateblue;
}

.program-section-program-container-item {
  width: 100%;
  padding: 20px;
  border: 1px solid rgb(216, 216, 216);
  position: relative;
  min-width: 350px;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.program-section-program-container-item-img {
  width: 110px;
  height: 20px;
  background-image: url("../images/16.svg");
  background-size: cover;
  text-align: center;
  margin-bottom: 10px;
  opacity: 0.85;
}

.program-section-program-container-item-descr {
  text-align: center;
}

.program-section-program-container-item-time {
  font-size: 45px;
  text-align: center;
  margin-bottom: 7px;
}
