@import url("--vars.css");

.dfcc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jc {
  justify-content: center;
}

.cw {
  color: white;
}

.cb {
  color: black;
}

.bgw {
  background-color: white;
}

.bgb {
  background-color: #1b1b1b;
}

.ffsc {
  font-family: "SantaCatarina";
}

.ffms {
  font-family: "MorfinSans";
}

.ffcvn {
  font-family: "Caravan";
}

.ffgvp {
  font-family: "GoodVibesPro";
}

.ffmrl {
  font-family: "MrLonely";
}

.ffmoc {
  font-family: "Oceanic";
}

.ffel {
  font-family: "Elowen";
}

.ffin {
  font-family: "Intro";
}

.ffpe {
  font-family: "Pehlevi";
}

.ffinf {
  font-family: "Informer";
}

.ffma {
  font-family: "Mavka";
}

.fs {
  font-size: 90px;
}

.block {
  display: block;
}

.none {
  display: none;
}

.green {
  text-align: center;
  color: rgb(62, 142, 75);
}

.yellow {
  text-align: center;
  color: rgb(187, 165, 80);
}

.red {
  text-align: center;
  color: rgb(155, 39, 39);
}
