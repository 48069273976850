@import url("--vars.css");

#love-story {
  width: 100%;
  max-width: 750px;
  margin-bottom: 70px;
  padding: 0 15px;
  position: relative;
}

#love-story-title {
  position: relative;
  font-weight: 200;
  font-style: italic;
  font-size: 50px;
  color: rgb(193, 56, 100);
  transform: scaleX(1.05);
  margin-bottom: 50px;
  margin-top: 40px;
}

#love-story-heart-abs {
  position: absolute;
  width: 200px;
  top: 102%;
  left: 50%;
  transform: translateX(-50%);
}

#love-story-grid {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  z-index: 1;
}

@media (max-width: 800px) {
  #love-story {
    padding: 0 20px;
  }

  #love-story-grid {
    gap: 15px;
  }
}

.love-story-grid-item {
  border-radius: var(--br);
  width: 100%;
  aspect-ratio: 1;
  box-shadow: var(--boxsh);
  opacity: 0.89;
  cursor: zoom-in;
  transition: all ease-in-out 0.2s;
}

.love-story-grid-item:hover {
  scale: 1.05;
}
