@import url("--vars.css");

#main-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 30px 0;
  overflow: visible;
}

@media (max-width: 800px) {
  #main-section {
    margin: 5px 0 30px 0;
    overflow: hidden;
  }
}

#main-title-wrapper {
  margin-top: 40px;
  position: relative;
}

#main-title {
  position: relative;
  font-size: min(12.9vw, 65px);
  font-weight: 400;
  font-style: italic;
  color: var(--main);
  margin-bottom: 33px;
  /* line-height: 0.85; */
  overflow: visible;
  transform: scaleX(1.1);
}

@media (max-width: 800px) {
  #main-title {
    font-size: minmax(12vw, 40px);
  }
}

#main-title-wreath1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 100%;
  transform: scale(1.65, 1.1) translateY(-0.25em);
  z-index: 0;
  background-image: url("../images/wreath1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* style={{
  position: "absolute",
  top: "0",
  left: "0",
  width: "150%",
  height: "150%",
  zIndex: 0,
  backgroundImage: `url(${Wreath1})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",
}} */

#main-title-e {
  transform: translate(-30px, 0.25em);
}

#main-title-t {
  transform: translate(30px, 0);
}

#main-title-line-abs {
  width: 350px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.5;
}

#main-p {
  margin-top: 40px;
  margin-bottom: 60px;
  max-width: 700px;
  padding: 0 25px;
  font-style: normal;
}
/* @media (max-width: 650px) {
  #main-p {
    margin-bottom: 40px;
  }
} */

#bride-groom-photos-div {
  width: 100%;
  max-width: 650px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  overflow: visible;
}

#photo-heart {
  max-width: min(100px, 17.5vw);
  opacity: 0.9;
  padding: 5px;
}

#Tania {
  /* margin-right: 20px; */
  margin-left: auto;
  max-width: 200px;
  width: 90%;
  aspect-ratio: 1;
  /* border: 3px solid rgb(165, 165, 165); */
  border-radius: 500px;
  /* margin-right: 0;
  margin-left: auto; */
  background-image: url("../images/Tania2.jpg");
  background-size: 125%;
  background-position: 40% 15%;
  opacity: 0.92;
  box-shadow: inset 0 0 20px var(--wc), inset 0 0 10px var(--wc),
    inset 0 0 5px var(--wc);
}

/* #bride-groom-photos-div-bride-inner-abs {
  top: 100%;
  margin: auto 25px 0 25px;
  height: 20%;
  width: 100%;
  background-color: rgba(var(--d1b), 0.4);
  border-radius: 100%;
  box-shadow: inset 0 0 50px var(--wc), inset 0 0 35px var(--wc),
    inset 0 0 40px var(--wc), inset 0 0 20px var(--wc), inset 0 0 10px var(--wc),
    inset 0 0 5px var(--wc);
} */

#Erman {
  max-width: 200px;
  /* margin-left: 20px; */
  margin-right: auto;
  width: 90%;
  aspect-ratio: 1;
  /* border: 3px solid rgb(165, 165, 165); */
  border-radius: 500px;
  /* margin-right: 0;
  margin-left: auto; */
  background-image: url("../images/Erman1.jpg");
  background-size: 130%;
  background-position: 35% 27.5%;
  opacity: 0.92;
  box-shadow: inset 0 0 20px var(--wc), inset 0 0 10px var(--wc),
    inset 0 0 5px var(--wc);
}

@media (max-width: 650px) {
  /* #bride-groom-photos-div-bride {
    height: 220px;
  }
  #bride-groom-photos-div-groom {
    height: 220px;
  } */
}
