@import url("--vars.css");

.address-section {
  /* margin-top: 20px; */
  width: 100%;
  /* padding: 20px 0; */
}

.address-section:last-of-type {
  margin-bottom: 20px;
}

.address-section-details-wrapper {
  margin: 55px 0 50px 0;
}

.address-section:nth-child(1) {
  margin-bottom: 40px;
}

.address-section-title {
  margin-top: 20px;

  font-size: 55px;
  margin-bottom: 15px;
}

.address-section-details {
  margin-bottom: 5px;
}

.address-section-a {
  margin-top: 10px;
}
