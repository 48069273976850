#main-picture {
  min-height: 800px;
  margin-top: 45px;
  width: 100%;
  background-image: url("../images/main-picture.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70% 90%;
  box-shadow: inset 0 0 100px var(--bg), inset 0 0 75px var(--bg),
    inset 0 0 50px var(--bg), inset 0 0 20px var(--bg), inset 0 0 10px var(--bg);
  border-radius: 300px;
}

@media (max-width: 800px) {
  #main-picture {
    margin-top: 0;
    width: 100vw;
    min-height: 720px;
    border-radius: 250px;
  }
}
